import Spinner from 'react-bootstrap/Spinner';
import styles from 'styles/Loader.module.scss'

export default function Loader() {
    return(
        <div className={styles.spinner}>
            <Spinner animation="border" role="status">
                <span className="sr-only"></span>
            </Spinner>
        </div>
    )
}