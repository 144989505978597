import CountrySelector from 'componentes/CountrySelector'
import Loader from 'componentes/Loader';
import ProgressBar from 'componentes/ProgressBar';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const availableCountries = ['ar', 'mx']

export default function Home() {
  const [country, setCountry] = useState(localStorage.getItem('country') ?? null);
  const [countryLoaded, setCountryLoaded] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if ('geolocation' in navigator && !country) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`;
        const response = await fetch(url);
        const data = await response.json();
        const country = data.results.find((result) => result.types.includes('country'));
        const countryCode = country.address_components.find((component) => component.types.includes('country')).short_name.toLowerCase();
        localStorage.setItem('country', countryCode);
        setCountry(countryCode);
        setCountryLoaded(true);
    },
    (error) => {
        console.error('Error al obtener la posición:', error);
        setCountryLoaded(true);
    })
  }
  }, []);

  useEffect(() => {
    if (country && availableCountries.includes(country)) {
      router.push(`/${country}`);
    }
    if(country && !availableCountries.includes(country)) {
      setCountryLoaded(true)
      setCountry(null)
    }
  }, [country]);

  if (countryLoaded && !country) return <CountrySelector />
  return <ProgressBar />
}
