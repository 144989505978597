import styles from 'styles/ProgressBar.module.scss';

function ProgressBar({ errorPage }) {
  return (
    <div className={styles.containerDiv}>
      <div className={styles.containerImg}>
        <img
          src={'/imgs/newLogo.svg'}
          alt='logo'
          className={styles.sizeImg}
        />
        {
          errorPage &&
          <p>Error 404 / página no encontrada</p>
        }
      </div>
    </div>
  );
}

export default ProgressBar;
