// fonts
export const fontPrimary = 'sans-serif';

// colors
export const primaryGreen = '#008648';
export const greenBar = '#00A656';
export const orangeBar = '#FE4806'
export const greenButtons = '#00904B';
export const textLight = '#FBFBFB';
export const textDark = '#011D0E';
export const textGray = '#4F545C';
export const darkBlue = '#3560D5';
export const darkRed = '#CC261E';
export const fillDark = '#011D0E';
export const snackBarGray = '#303640';
export const yellowDiscount = '#FFEC3E';